import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import { loadPosts } from '../../redux/modules/search'

class SmartSearchField extends React.Component {
  constructor(props) {
    super(props)

    this.timeout = 0
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.loadPosts = this.loadPosts.bind(this)
  }

  loadPosts(request, pagination) {
    return this.props.dispatch(loadPosts(request, pagination).bind(this))
  }

  handleKeyUp(e) {
    const inputValue = e.target.value
    const { pagination } = this.props
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.loadPosts(inputValue, pagination)
    }, 250)
  }

  componentDidMount() {
    const { search, pagination } = this.props
    if (search) {
      this.loadPosts(search, pagination)
    }
  }

  render() {
    const { search, url, strings } = this.props
    return (
      <form role="search" method="get" className="searchform" action={url}>
        <label className="searchform__label" htmlFor="s">
          {strings.search}
        </label>
        <input
          type="text"
          className="searchform__input"
          defaultValue={search}
          placeholder={strings.placeholder}
          name="s"
          // id="s"
          onKeyUp={this.handleKeyUp}
        />
        <button type="submit" className="searchsubmit">
          <i className="la la-arrow-right" />
        </button>
      </form>
    )
  }
}

SmartSearchField.propTypes = {
  isMobile: PropTypes.bool,
  pagination: PropTypes.bool,
  search: PropTypes.string,
  url: PropTypes.string,
  strings: PropTypes.object,
  dispatch: PropTypes.func,
}

export default SmartSearchField
